import React, {
  useState,
  type Dispatch,
  type SetStateAction,
  type FocusEvent,
  type ChangeEvent,
  useEffect,
  type ChangeEventHandler,
  type FocusEventHandler,
} from "react";
import dynamic from "next/dynamic";
import { format, parseISO } from "date-fns";

import { DateField } from "@cruk/cruk-react-components";

import { isValidDate } from "@fwa/src/utils/timeUtils";
import { prefixSingleWithZero } from "@fwa/src/utils/formatUtils";

import {
  StyledDatePickerWrapper,
  TextInputWrapper,
  DatePickerHeightPlaceholder,
} from "./styles";

const ReactDatePicker = dynamic(
  // @ts-expect-error dynamic +'react-datepicker' can't find types, standard import is fine, inference is fine
  () => import("react-datepicker"),
  {
    ssr: false,
  },
);

export type DateDataType = {
  startDay: string | null;
  startMonth: string | null;
  startYear: string | null;
  endDay: string | null;
  endMonth: string | null;
  endYear: string | null;
};

export type StartAndEndDateType = {
  startDate?: Date | null;
  endDate?: Date | null;
};

type DateRangePickerProps = {
  /** initial selected date or start date for selected range */
  startDate?: Date;
  /** end date for selected range */
  endDate?: Date;
  /** if using external state and deferring validation to a form library with an external state, this prevents default validation */
  dateState?: DateDataType;
  /** The legend text wrapping the start date day-month-year group */
  startDateLabel?: string;
  /** The legend text wrapping the end date day-month-year group */
  endDateLabel?: string;
  /** custom text in between the legend text and the text input of the start date */
  startDateHint?: string;
  /** custom text in between the legend text and the text input of the end date */
  endDateHint?: string;
  /** min date selectable in the calendar view, when not using external state and validation messages are used also effects validation messages for text input */
  minDate?: Date;
  /** max date selectable in the calendar view, when not using external and validation messages are used also effects validation messages for text input */
  maxDate?: Date;
  /** allows date or date range to be unset, when not using external and validation messages are used also effects validation messages for text input */
  allowNullDate?: boolean;
  /** callback for when date or date range is selected in calendar view, this is needed as calendar view selection doesn't trigger a change event */
  setCurrentValue: (selection: Date | StartAndEndDateType) => void;
  /** call back for external components to know if date selection is invalid */
  setIsDateRangeValid?:
    | Dispatch<SetStateAction<boolean>>
    | ((hasError: boolean) => void);
  /** toggle of calendar input only view */
  hideTextInput?: boolean;
  /** toggle of date or date range selection */
  isDateRange?: boolean;
  /** if using external state and deferring validation to a form library exposing change handler for start date, this prevents default validation */
  onChangeStartDate?: ChangeEventHandler<HTMLInputElement> | undefined;
  /** if using external state and deferring validation to a form library exposing blur handler for start date, this prevents default validation */
  onBlurStartDate?: FocusEventHandler<HTMLInputElement> | undefined;
  /** if using external state and deferring validation to a form library exposing focus handler usefull for preventing error messages until user has entered a full date */
  onFocusStartDate?: FocusEventHandler<HTMLInputElement> | undefined;
  /** if using external state and deferring validation to a form library exposing change handler for end date, this prevents default validation */
  onChangeEndDate?: ChangeEventHandler<HTMLInputElement> | undefined;
  /** if using external state and deferring validation to a form library exposing blur handler for start date, this prevents default validation */
  onBlurEndDate?: FocusEventHandler<HTMLInputElement> | undefined;
  /** if using external state and deferring validation to a form library exposing focus handler usefull for preventing error messages until user has entered a full date */
  onFocusEndDate?: FocusEventHandler<HTMLInputElement> | undefined;
  /** if using external state and deferring validation to a form library pass error message back into component for start date */
  errorMessageStart?: string;
  /** if using external state and deferring validation to a form library pass error message back into component for end date */
  errorMessageEnd?: string;
};

/** Component for selecting a date or date range with a calendar input, or a calendar input with optional date input fields, has default state management and validation but can this can be deferred to an external form and validation library */
export const DateRangePicker = ({
  startDate: startDateProps,
  endDate: endDateProps,
  dateState,
  startDateLabel = "start date",
  endDateLabel = "end date",
  startDateHint = `For example 31 10 ${new Date().getFullYear()}`,
  endDateHint = `For example 31 11 ${new Date().getFullYear()}`,
  minDate,
  maxDate,
  allowNullDate,
  setCurrentValue,
  setIsDateRangeValid,
  isDateRange = true,
  hideTextInput = false,
  onChangeStartDate,
  onBlurStartDate,
  onFocusStartDate,
  onChangeEndDate,
  onBlurEndDate,
  onFocusEndDate,
  errorMessageStart,
  errorMessageEnd,
}: DateRangePickerProps) => {
  const [dateData, setDateData] = useState<DateDataType>({
    startDay: `${
      startDateProps
        ? prefixSingleWithZero(startDateProps.getDate().toString())
        : ""
    }`,
    startMonth: `${
      startDateProps
        ? prefixSingleWithZero(`${startDateProps.getMonth() + 1}`)
        : ""
    }`,
    startYear: `${startDateProps ? startDateProps.getFullYear() : ""}`,
    endDay: `${
      endDateProps
        ? prefixSingleWithZero(endDateProps.getDate().toString())
        : ""
    }`,
    endMonth: `${
      endDateProps ? prefixSingleWithZero(`${endDateProps.getMonth() + 1}`) : ""
    }`,
    endYear: `${endDateProps ? endDateProps.getFullYear() : ""}`,
  });
  // These additional state are need for the range picker to work
  const [startDateState, setStartDateState] = useState(startDateProps);
  const [endDateState, setEndDateState] = useState(endDateProps);

  // This is not ideal, because internal features like change of text fields and range selections can set date
  // date range is handled as internal state, so change in external props doesn't update date selection after first render
  // this use effect listens for change in props and makes sure that the change is propergated though internal state.
  useEffect(() => {
    if (startDateState !== startDateProps || endDateState !== endDateProps) {
      setStartDateState(startDateProps);
      setEndDateState(endDateProps);
    }
  }, [startDateProps, endDateProps]);

  const [startDateValidationMessage, setStartDateValidationMessage] =
    useState<string>("");
  const [endDateValidationMessage, setEndDateValidationMessage] =
    useState<string>("");

  const getDatesFromState = (): {
    startDate: Date;
    endDate: Date;
    startValid: boolean;
    endValid: boolean;
  } => {
    const startDateFromState = parseISO(
      `${dateData.startYear || ""}-${prefixSingleWithZero(
        dateData.startMonth || "",
      )}-${prefixSingleWithZero(dateData.startDay || "")}`,
    );
    const endDateFromState = parseISO(
      `${dateData.endYear || ""}-${prefixSingleWithZero(
        dateData.endMonth || "",
      )}-${prefixSingleWithZero(dateData.endDay || "")}`,
    );
    const startValid = isValidDate(startDateFromState);
    const endValid = isValidDate(endDateFromState);
    return {
      startDate: startDateFromState,
      endDate: endDateFromState,
      startValid,
      endValid,
    };
  };

  const handeleDateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    setDateData({
      ...dateData,
      [`${input.name}`]: input.value,
    });
    setStartDateValidationMessage("");
    setEndDateValidationMessage("");
  };

  // validate start date  on blur
  const handleStartDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    const input = event.target;
    //  automatically set end date to start date if it doesn't exist
    setDateData({
      ...dateData,
      [`${input.name}`]: prefixSingleWithZero(input.value),
      [`endDay`]:
        dateData.endDay ||
        (dateData.startDay &&
          dateData.startMonth &&
          dateData.startYear &&
          prefixSingleWithZero(dateData.startDay)),
      [`endMonth`]:
        dateData.endMonth ||
        (dateData.startDay &&
          dateData.startMonth &&
          dateData.startYear &&
          prefixSingleWithZero(dateData.startMonth)),
      [`endYear`]:
        dateData.endYear ||
        (dateData.startDay &&
          dateData.startMonth &&
          dateData.startYear &&
          prefixSingleWithZero(dateData.startYear)),
    });

    validateAndSetStartDate();
  };

  const validateAndSetStartDate = () => {
    const { startDate, endDate, startValid, endValid } = getDatesFromState();
    if (startValid) {
      setStartDateState(startDate);
    }
    //  automatically set end date to start date if it doesn't exist

    if (endValid) {
      setEndDateState(endDate);
    } else if (startValid) {
      setEndDateState(startDate);
    }

    const emptyAndAllowed =
      allowNullDate &&
      !`${dateData.startDay || ""}${dateData.startMonth || ""}${
        dateData.startYear || ""
      }${dateData.endDay || ""}${dateData.endMonth || ""}${
        dateData.endYear || ""
      }`.trim().length;

    if (!emptyAndAllowed && (!startDate || !startValid)) {
      setStartDateValidationMessage(
        `Please enter a valid ${
          isDateRange ? startDateLabel : "date"
        } with DD MM YYYY`,
      );
    } else if (isDateRange && endDate && startDate > endDate) {
      setEndDateValidationMessage(
        `The ${startDateLabel} must be same as or before the ${endDateLabel.replace("(optional)", "")}`,
      );
    } else if (minDate && startDate < minDate) {
      setStartDateValidationMessage(
        `The ${
          isDateRange ? startDateLabel : "date"
        } must be after ${format(minDate, "dd/MM/yyyy")}`,
      );
    } else if (maxDate && startDate > maxDate) {
      setStartDateValidationMessage(
        `The ${
          isDateRange ? startDateLabel : "date"
        } must be before ${format(maxDate, "dd/MM/yyyy")}`,
      );
    } else if (isDateRange) {
      setEndDateValidationMessage("");
      setStartDateValidationMessage("");
      setCurrentValue({
        startDate: startValid ? startDate : undefined,
        endDate: endValid ? endDate : startValid ? startDate : undefined,
      });
    } else if (!isDateRange) {
      setEndDateValidationMessage("");
      setStartDateValidationMessage("");
      setCurrentValue(startDate);
    }
  };

  // validate end date on blur
  const handleEndDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    const input = event.target;
    setDateData({
      ...dateData,
      [`${input.name}`]: prefixSingleWithZero(input.value),
    });

    validateAndSetEndDate();
  };

  const validateAndSetEndDate = () => {
    const { startDate, endDate, startValid, endValid } = getDatesFromState();
    if (startValid) {
      setStartDateState(startDate);
    }
    if (endValid) {
      setEndDateState(endDate);
    }

    const emptyAndAllowed =
      allowNullDate &&
      !`${dateData.endDay || ""}${dateData.endMonth || ""}${
        dateData.endYear || ""
      }`.trim().length;

    if (emptyAndAllowed) setEndDateState(startDate);

    if (!startValid && endValid) {
      setEndDateValidationMessage("Start date is required");
    } else if (!emptyAndAllowed && !endValid) {
      setEndDateValidationMessage(
        "Please enter a valid end date with DD MM YYYY",
      );
    } else if (startDate && startDate > endDate) {
      setEndDateValidationMessage(
        `The ${startDateLabel} must be same as or before the ${endDateLabel.replace("(optional)", "")}`,
      );
    } else if (endDate && minDate && endDate < minDate) {
      setEndDateValidationMessage(
        `The ${endDateLabel.replace("(optional)", "")} must be after ${format(minDate, "dd/MM/yyyy")}`,
      );
    } else if (endDate && maxDate && endDate > maxDate) {
      setEndDateValidationMessage(
        `The ${endDateLabel.replace("(optional)", "")} must be before ${format(maxDate, "dd/MM/yyyy")}`,
      );
    } else {
      setEndDateValidationMessage("");
      setCurrentValue({
        startDate: startValid ? startDate : undefined,
        endDate: endValid ? endDate : undefined,
      });
    }
  };

  const rangePicked = (date: [Date | null, Date | null]) => {
    const [start, end] = date;
    setStartDateValidationMessage("");
    setEndDateValidationMessage("");
    setStartDateState(start || undefined);
    setEndDateState(end || undefined);
    setDateData({
      startDay: `${start ? start.getDate() : ""}`,
      startMonth: `${start ? start.getMonth() + 1 : ""}`,
      startYear: `${start ? start.getFullYear() : ""}`,
      endDay: `${end ? end.getDate() : ""}`,
      endMonth: `${end ? end.getMonth() + 1 : ""}`,
      endYear: `${end ? end.getFullYear() : ""}`,
    });
    setCurrentValue({ startDate: start, endDate: end });
  };

  const datePicked = (date: Date | null) => {
    setStartDateValidationMessage("");
    setEndDateValidationMessage("");
    setStartDateState(date || undefined);
    setEndDateState(date || undefined);
    setDateData({
      startDay: `${date ? date.getDate() : ""}`,
      startMonth: `${date ? date.getMonth() + 1 : ""}`,
      startYear: `${date ? date.getFullYear() : ""}`,
      endDay: `${date ? date.getDate() : ""}`,
      endMonth: `${date ? date.getMonth() + 1 : ""}`,
      endYear: `${date ? date.getFullYear() : ""}`,
    });
    if (!date) {
      return;
    }
    setCurrentValue(date);
  };

  // we don't want to show the error message
  // when people are still hopping from date to month to year
  const handleStartDateFocus = () => {
    setStartDateValidationMessage("");
  };
  const handleEndDateFocus = () => {
    setEndDateValidationMessage("");
  };

  useEffect(() => {
    const invalid =
      startDateValidationMessage.length || !!endDateValidationMessage.length;
    if (setIsDateRangeValid) {
      setIsDateRangeValid(!invalid);
    }
  }, [startDateValidationMessage, endDateValidationMessage]);

  return (
    <StyledDatePickerWrapper>
      {!hideTextInput && (
        <TextInputWrapper>
          <DateField
            dayName="startDay"
            monthName="startMonth"
            yearName="startYear"
            hintText={startDateHint}
            day={dateState ? dateState.startDay || "" : dateData.startDay || ""}
            month={
              dateState ? dateState.startMonth || "" : dateData.startMonth || ""
            }
            year={
              dateState ? dateState.startYear || "" : dateData.startYear || ""
            }
            label={startDateLabel ?? ""}
            onChange={onChangeStartDate || handeleDateChanged}
            onBlur={onBlurStartDate || handleStartDateBlur}
            onFocus={onFocusStartDate || handleStartDateFocus}
            errorMessage={errorMessageStart || startDateValidationMessage}
          />
          {isDateRange && (
            <DateField
              dayName="endDay"
              monthName="endMonth"
              yearName="endYear"
              hintText={endDateHint}
              day={dateState ? dateState.endDay || "" : dateData.endDay || ""}
              month={
                dateState ? dateState.endMonth || "" : dateData.endMonth || ""
              }
              year={
                dateState ? dateState.endYear || "" : dateData.endYear || ""
              }
              label={endDateLabel ?? ""}
              onChange={onChangeEndDate || handeleDateChanged}
              onBlur={onBlurEndDate || handleEndDateBlur}
              onFocus={onFocusEndDate || handleEndDateFocus}
              errorMessage={errorMessageEnd || endDateValidationMessage}
            />
          )}
        </TextInputWrapper>
      )}
      <DatePickerHeightPlaceholder>
        {isDateRange ? (
          <ReactDatePicker
            onChange={rangePicked}
            selectsRange={true}
            selected={startDateState}
            startDate={startDateState}
            endDate={endDateState}
            inline
            aria-label={"pick a date range"}
            minDate={minDate}
            maxDate={maxDate}
            // locale="en-GB"
            selectsMultiple={undefined}
          />
        ) : (
          <ReactDatePicker
            onChange={datePicked}
            selected={startDateState}
            startDate={startDateState}
            endDate={startDateState}
            inline
            aria-label={"pick a date"}
            minDate={minDate}
            maxDate={maxDate}
            // locale="en-GB"
            selectsMultiple={undefined}
          />
        )}
      </DatePickerHeightPlaceholder>
    </StyledDatePickerWrapper>
  );
};

export default DateRangePicker;
